import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Autocomplete, Box, Button, FormControl, IconButton, TextField, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {IIssueRequirement} from "../../types";
import {IListIssueRequirement} from "../../../sale-order-issue-requirements/types";

interface IFormInput {
    issueRequirements: IIssueRequirement[];
}

interface IssueRequirementsFormProps {
    issueRequirements: IListIssueRequirement[];
}

const IssueRequirementsForm = forwardRef(({ issueRequirements }: IssueRequirementsFormProps, ref) => {
    const [issueRequirementsToSelect, setIssueRequirementsToSelect]= useState<IListIssueRequirement[]>([])
    const [selectedRequirement, setSelectedRequirement] = useState<IListIssueRequirement | null>(null);

    const { control, setValue, getValues,trigger, formState: { errors,isValid } } = useForm<IFormInput>({
        defaultValues: {
            issueRequirements: [],
        },
        mode: 'onChange',
    });
    useEffect(() => {
        setIssueRequirementsToSelect(issueRequirements);
    }, [issueRequirements]);

    const handleAddRequirement = () => {
        if (selectedRequirement) {
            append({ issueRequirementId: selectedRequirement.id, comment: selectedRequirement.needComment?'':undefined });
            setIssueRequirementsToSelect((prev) =>
                prev.filter((requirement) => requirement.id !== selectedRequirement.id)
            );
            setSelectedRequirement(null);
            if(selectedRequirement.needComment){
                const totalElements = getValues().issueRequirements.length;
                trigger(`issueRequirements.${totalElements-1}.comment`);
            }

        }
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'issueRequirements',
    });

    useImperativeHandle(ref, () => ({
        getFormValues: () => getValues(),
        isValidForm: () => isValid,
        triggerValidation: () => trigger(),
    }));

    const isRequiredComment = (requirementId:string) =>{
        const requirement = issueRequirements.find(x=> x.id === requirementId);
       return requirement?requirement.needComment: false;
    }

    const handleDeleteRequirement = (index:number) => {
        const requirementId = getValues(`issueRequirements.${index}.issueRequirementId`);
        const requirement = issueRequirements.find(x=> x.id === requirementId);
        if(requirement) {
            remove(index);
            setIssueRequirementsToSelect((prev) =>
                [...prev,requirement]
            );
        }
    }


    return (
        <Box display="flex" flexDirection="column" gap={2} mb={2} pl={1}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Requerimientos
            </Typography>
            <Box display="flex" alignItems="flex-start" gap={2}>
                <Box display="flex" alignItems="center" flex={3}>
                    <FormControl sx={{ width: '100%' }}>
                        <Autocomplete
                            options={issueRequirementsToSelect}
                            getOptionLabel={(option) => `${option.name}`}
                            value={selectedRequirement}
                            onChange={(event, value) => setSelectedRequirement(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    label="Requerimiento"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                </Box>
                <Box display="flex" justifyContent="flex-start" gap={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddRequirement}
                        disabled={!selectedRequirement} // Desactiva el botón si no hay selección
                    >
                        Agregar
                    </Button>
                </Box>
            </Box>
            {fields.map((item, index) => (
                <Box
                    key={item.id}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                >
                    <Box display="flex" flexDirection="column" flex={1} gap={1} p={1}>
                        <Controller
                            name={`issueRequirements.${index}.issueRequirementId`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Este campo es requerido' },
                            }}
                            render={({ field }) => (
                                <FormControl sx={{ width: '100%' }}>
                                    <Autocomplete
                                        disabled={true}
                                        options={issueRequirements}
                                        getOptionLabel={(option: IListIssueRequirement) => `${option.name}`}
                                        value={
                                            issueRequirements.find((option) => option.id === field.value) || null
                                        }
                                        onChange={(event, value) => {
                                            const selectedValues = (value as IListIssueRequirement)?.id || '';
                                            field.onChange(selectedValues);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                required
                                                disabled={true}
                                                label="Requerimiento"
                                                fullWidth
                                                error={!!errors?.issueRequirements?.[index]?.issueRequirementId}
                                                helperText={errors?.issueRequirements?.[index]?.issueRequirementId?.message}
                                            />
                                        )}
                                    />
                                </FormControl>
                            )}
                        />

                        {isRequiredComment(getValues(`issueRequirements.${index}.issueRequirementId`)) && (
                            <Controller
                                name={`issueRequirements.${index}.comment`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Este campo es requerido' },
                                    maxLength:{ value:1000, message: 'La cantidad de caracteres máximo es 1000' }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        error={!!errors?.issueRequirements?.[index]?.comment}
                                        helperText={errors?.issueRequirements?.[index]?.comment?.message}
                                        {...field}
                                        label="Comentarios para emisión"
                                        variant="outlined"
                                        size="small"
                                        rows={3}
                                        required
                                        fullWidth
                                        multiline
                                    />
                                )}
                            />
                        )}
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        sx={{ alignSelf: 'center' }}
                    >
                        <IconButton color="secondary" onClick={() => handleDeleteRequirement(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Box>
            ))}


        </Box>
    );
});

export default IssueRequirementsForm;

export const tariffCalculatorCardStamp = ['Master','Visa','Amex'];
export const tariffForVisa = [0.95,3.10,3.95,3.95,3.95,3.95,3.95,null,null,null,3.95,null,null];
export const tariffForMaster = [0.99,3.1,3.60,3.60,3.60,3.60,3.60,null,null,null,null,null,3.60];

export const calculateTariff = (cardStamp:string,totalFee:number):number|null|undefined=>{
     if(totalFee >12 || totalFee <0) return null;
    switch (cardStamp){
        case 'Visa':
            return tariffForVisa[totalFee];
        case 'Master':
            return tariffForMaster[totalFee];
        case 'Amex':
            return null;

    }
}
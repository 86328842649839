import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel, IconButton,
    InputAdornment,
    TextField, Tooltip,
    Typography, useTheme
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {IBitrixDealInfoToOrder, IIssueRequirement} from "../../types";
import {appRoutes} from "../../../../core/const";
import IssueRequirementsForm from "../IssueRequirementsForm/IssueRequirementsForm";
import {IListIssueRequirement} from "../../../sale-order-issue-requirements/types";

export interface SaleOrderCreateFormProps {
    handleSubmitForm: (value: ICreateSaleOrderFormValue) => void;
    handleCheckDeal : (dealId:string)=>void;
    dealInfo:IBitrixDealInfoToOrder|null;
    paymentLinkURL:string|null;
    handleCopyLinkURL: ()=> void;
    loading: boolean;
    issueRequirements:IListIssueRequirement[]
}

export interface ICreateSaleOrderFormValue {
    amount: string;
    dealId: string;
    fileNumber: string;
    departureDate: string;
    toUseInSameIssue: boolean;
    fee:string;
    issueRequirements:IIssueRequirement[]
}

export const SaleOrderCreateForm: React.FC<SaleOrderCreateFormProps> = ({issueRequirements,handleSubmitForm, loading,dealInfo,handleCheckDeal,paymentLinkURL,handleCopyLinkURL}) => {

    const theme = useTheme();
    const methods = useForm<ICreateSaleOrderFormValue>({
        mode: 'all',
        defaultValues: {amount: '', dealId: '', fileNumber: '', departureDate: '', toUseInSameIssue: false, fee:''}
    });
    const formRef = useRef<any>(null);
    const [isRequirementsFormValid, setIsRequirementsFormValid] = useState(false);

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,setValue,trigger,watch
    } = methods;
    const watchFields = watch(["fileNumber", "departureDate"]);

    useEffect(() => {
        const fileNumberValue = dealInfo?.fileNumber||'';
        const departureDateValue = dealInfo?.departureDate||'';
        setValue('fileNumber',fileNumberValue);
        setValue('departureDate',departureDateValue);
        if(watchFields[0]!=fileNumberValue){
            trigger('fileNumber');
        }
        if(watchFields[1]!=departureDateValue){
            trigger('departureDate');
        }
    }, [dealInfo]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.triggerValidation();
            setIsRequirementsFormValid(formRef.current.isValidForm());
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (formRef.current) {
                setIsRequirementsFormValid(formRef.current.isValidForm());
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);

    const getIssueRequirementsValues = () => {
        if (formRef.current) {
            const formValues = formRef.current.getFormValues();
            return formValues.issueRequirements;
        }
        return [];
    };


    return (
        <>
            <Card sx={{maxWidth: 600, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{ 'Crear nueva orden de venta'}</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{
                                        min: {value: 1, message: 'El valor mínimo es 1'},
                                        required: {value: true, message: 'Este campo es requerido'},
                                        pattern: {
                                            value: /^[0-9]*[.,]?[0-9]+$/,
                                            message: 'El campo monto debe ser un número válido'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Monto"
                                            required={true}
                                            size="small"
                                            type="text"
                                            helperText={errors.amount ? errors.amount.message : ''}
                                            error={!!errors.amount}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                            }}
                                            {...field}

                                        />
                                    )}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>

                                <Controller
                                    name="fee"
                                    control={control}
                                    rules={{
                                        min: {value: 1, message: 'El valor mínimo es 1'},
                                        required: "Este campo es requerido", // Validación de campo requerido
                                        pattern: { value: /^[0-9]*$/, message: "Solo se permiten números" } // Validación para solo números
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            fullWidth
                                            label="Cuotas"
                                            placeholder="Inserte el número de cuotas"
                                            required
                                            size="small"
                                            type="text"
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                                field.onChange(value);
                                            }}
                                            error={!!errors.fee}
                                            helperText={errors.fee ? errors.fee.message : ''}
                                        />
                                    )}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" gap={2} flex={2} p={1}>

                                <Controller
                                    name="dealId"
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido", // Validación de campo requerido
                                        pattern: { value: /^[0-9]*$/, message: "Solo se permiten números" } // Validación para solo números
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            fullWidth
                                            label="Número de la negociación"
                                            placeholder="Inserte el número y presione enter"
                                            required
                                            size="small"
                                            type="text"
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                                field.onChange(value);
                                                setValue('fileNumber','');
                                                setValue('departureDate','');
                                                trigger('fileNumber');
                                                trigger('departureDate');
                                                // Notifica a react-hook-form del cambio
                                            }}
                                            error={!!errors.dealId}
                                            helperText={errors.dealId ? errors.dealId.message : ''}
                                            onKeyDown={(event)=> {
                                                if(event.keyCode == 13 && !errors.dealId) {
                                                    handleCheckDeal(getValues().dealId)
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="fileNumber"
                                control={control}
                                rules={{
                                    required: "Este campo es requerido", // Validación de campo requerido
                                    pattern: { value: /^[0-9]*$/, message: "Solo se permiten números" } // Validación para solo números
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Número de file"
                                        required={true}
                                        size="small"
                                        type="text"
                                        inputProps={{
                                            readOnly: true,
                                            style: { cursor: 'not-allowed' }, // Aplica el cursor en el nivel del input
                                        }}
                                        InputProps={{
                                            style: {
                                                backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                                            }
                                        }}
                                        helperText={errors.fileNumber ? errors.fileNumber.message : ''}
                                        error={!!errors.fileNumber}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={2} p={1}>
                            <Controller
                                name="departureDate"
                                control={control}
                                rules={{
                                    required: "Este campo es requerido", // Validación de campo requerido
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Fecha de salida"
                                        required={true}
                                        size="small"
                                        type="text"
                                        inputProps={{
                                            readOnly: true,
                                            style: { cursor: 'not-allowed' }, // Aplica el cursor en el nivel del input
                                        }}
                                        InputProps={{
                                            style: {
                                                backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                                            }
                                        }}
                                        helperText={errors.departureDate ? errors.departureDate.message : ''}
                                        error={!!errors.departureDate}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">

                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="toUseInSameIssue"
                                    control={control}
                                    render={({field}) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} checked={field.value}/>}
                                            label="Para ser usada en la misma emisión"
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        {/* Mostrar el enlace solo si la URL está disponible */}
                        {paymentLinkURL && (
                            <Box mt={1} mb={1} display="flex" alignItems="center" gap={1}>
                                <Typography variant="body1" style={{fontWeight:"bold"}}>
                                    Link de pago: <p style={{fontWeight:"bold", fontStyle:'italic', fontSize:13, color:theme.palette.primary.light}}>{paymentLinkURL}</p>
                                </Typography>

                                <Tooltip title="Copiar Link de pago">
                                    <IconButton onClick={handleCopyLinkURL}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}

                        <IssueRequirementsForm ref={formRef} issueRequirements={issueRequirements}/>

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(appRoutes.saleOrder.list)}
                            >
                                Volver al listado
                            </Button>
                            {!paymentLinkURL && <Button
                                variant="contained"
                                disabled={!isValid || loading ||!isRequirementsFormValid}
                                color="primary"
                                onClick={() => handleSubmitForm({...getValues(),issueRequirements:getIssueRequirementsValues()})}
                            >
                                Guardar
                            </Button>}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};
import React, {useCallback, useEffect, useState} from 'react';
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import {IFilterSaleOrder, IListSaleOrder} from "../../types";
import {getSalesOrder} from "../../services";
import SalesOrderList from "../../components/SalesOrderList/SalesOrderList";
import {getAllIssueRequirements} from "../../../sale-order-issue-requirements/services";
import {IListIssueRequirement} from "../../../sale-order-issue-requirements/types";

export const defaultFilterSaleOrderList:IFilterSaleOrder = {
    id: '',
    amount: '',
    amountFrom: '',
    amountTo: '',
    dealId: '',
    fileNumber: '',
    departureDateFrom: '',
    departureDateTo: '',
    toUseInSameIssue: '',
    fee: '',
    sellerName: '',
    createdAtFrom: '',
    createdAtTo: '',
    hasIssueRequirements:'',
    issueRequirementId:''
}

const SalesOrderListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([{'field':'createdAt',order:'desc'}]);
    const [filters, setFilters] = useState<IFilterSaleOrder>(defaultFilterSaleOrderList);
    const [items, setItems] = useState<IListSaleOrder[]>([]);
    const [issueRequirements, setIssueRequirements]= useState<IListIssueRequirement[]>([]);
    const navigate = useNavigate();

    const onAdd = () => navigate('/sales-order/create');

    const loadIssueRequirements = async ()=>{
        try {
            setLoading(true);
            const result = (await getAllIssueRequirements()).data;
            setIssueRequirements([...result]);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los requerimientos para emisión')
            });
        } finally {
            setLoading(false);
        }
    }


    const loadSalesOrder = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getSalesOrder(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de órdenes de ventas')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadSalesOrder();
    }, [loadSalesOrder]);

    useEffect(() => {
        loadIssueRequirements();
    }, []);


    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <SalesOrderList title={'Órdenes de venta'}
                            items={items}
                            onAdd={onAdd}
                            filters={filters}
                            setFilters={setFilters}
                            order={order}
                            setOrder={setOrder}
                            search={search}
                            setSearch={setSearch}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            totalElements={totalElements}
                            issueRequirements={issueRequirements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default SalesOrderListContainer;

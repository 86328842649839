import React, {Dispatch, SetStateAction, useState} from 'react';

import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {FilterList as FilterListIcon, Search as SearchIcon} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {IFilterListAllCardPortfolioAuthorization, IListAllCardPortfolioAuthorization} from "../../types";
import {IDetailAirline} from "../../../airlines/types";
import {IListAuthorizationOperator} from "../../../authorization-operators/types";
import {appRoutes} from "../../../../core/const";
import {defaultListAllCardPortfolioAuthorizationsFilter} from "../../constants";
import {travellerPosName} from "../../../cards-portfolio/modules/card-portafolio-authorizations/constants";
import ScrollableTableContainer from "../../../../core/components/ScrollableTableContainer/ScrollableTableContainer";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";

interface AuthorizationsListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterListAllCardPortfolioAuthorization;
    setFilters: (filter: IFilterListAllCardPortfolioAuthorization) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListAllCardPortfolioAuthorization[];
    totalElements: number;
    airlines: IDetailAirline[],
    operators: IListAuthorizationOperator[],
}

const AuthorizationsList: React.FC<AuthorizationsListProps> = ({
                                                                   title,
                                                                   search,
                                                                   setSearch,
                                                                   page,
                                                                   setPage,
                                                                   pageSize,
                                                                   totalElements,
                                                                   setPageSize,
                                                                   filters,
                                                                   setFilters,
                                                                   order,
                                                                   setOrder,
                                                                   items,
                                                                   airlines,
                                                                   operators,
                                                               }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterListAllCardPortfolioAuthorization>(defaultListAllCardPortfolioAuthorizationsFilter);

    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(null);
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    const handleChangeExpirySoon = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, expiringSoon: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, expiringSoon: undefined}));
        }

    };

    const handleChangeIsPosAuthorization = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, isPosAuthorization: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, isPosAuthorization: undefined}));
        }

    };
    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListAllCardPortfolioAuthorization) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    const getVieDetailCardPortfolioURL = (cardPortfolioId: number): string => {
        const baseUrl = window.location.origin;
        const newRoute = appRoutes.cardPortfolio.detail.replace(':id', cardPortfolioId + '');
        return `${baseUrl}${newRoute}`;
    }

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}} style={{paddingLeft: 0}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1, minWidth:350}}>
                            <TextField
                                label="ID igual a"
                                size='small'
                                value={internalFilters.id}
                                onChange={handleFilterChange('id')}
                                fullWidth
                            />
                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    options={airlines}
                                    getOptionLabel={(option: IDetailAirline) => `${option.code} - ${option.name}`}
                                    value={airlines.find((option) => option.code === internalFilters.airlineCode) || null}
                                    onChange={(event, value) => {
                                        const selectedValue = (value as IDetailAirline)?.code || '';
                                        handleFilterChangeValue('airlineCode', selectedValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="Compañía aérea igual a"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    options={operators}
                                    getOptionLabel={(option: IListAuthorizationOperator) => `${option.name}`}
                                    value={
                                        operators.find((option) => option.id === internalFilters.operatorId) || null
                                    }
                                    onChange={(event, value) => {
                                        const selectedValue = (value as IListAuthorizationOperator)?.id || '';
                                        handleFilterChangeValue('operatorId', selectedValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size={'small'}
                                            variant="outlined"
                                            label="Operador igual a"
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormControl>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountAuthorized}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountAuthorized', value);
                                }}
                                label="Monto autorizado igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountAuthorizedFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountAuthorizedFrom', value);
                                }}
                                label="Monto autorizado mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountAuthorizedTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountAuthorizedTo', value);
                                }}
                                label="Monto autorizado menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="ID de tarjeta en cartera igual a"
                                value={internalFilters.cardPortfolioId}
                                placeholder="ID de tarjeta en cartera igual a"
                                size="small"
                                type="text"
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                    handleFilterChangeValue('cardPortfolioId', value);
                                }}
                            />
                            <TextField
                                label="Código de autorización igual a"
                                size='small'
                                value={internalFilters.authorizationCode}
                                onChange={handleFilterChange('authorizationCode')}
                                fullWidth
                            />
                            <TextField
                                label="Número de la negociación igual a"
                                size='small'
                                value={internalFilters.dealId}
                                onChange={handleFilterChange('dealId')}
                                fullWidth
                            />
                            <TextField
                                label="Número de file igual a"
                                size='small'
                                value={internalFilters.fileNumber}
                                onChange={handleFilterChange('fileNumber')}
                                fullWidth
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 300}}>
                                    <DatePicker
                                        label="Fecha de autorización desde"
                                        format="YYYY-MM-DD"
                                        value={createdAtFrom ? dayjs(createdAtFrom) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtFrom(newValue);
                                            handleFilterChangeValue('createdAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de autorización hasta"
                                        format="YYYY-MM-DD"
                                        value={createdAtTo ? dayjs(createdAtTo) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtTo(newValue);
                                            handleFilterChangeValue('createdAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={createdAtFrom ? dayjs(createdAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>

                            <FormControl fullWidth>
                                <InputLabel id="expiry-soon-label">Próximo a vencer</InputLabel>
                                <Select
                                    size='small'
                                    labelId="expiry-soon-select-label"
                                    value={internalFilters.expiringSoon}
                                    label="Próximo a vencer"
                                    onChange={handleChangeExpirySoon}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Autorizado por contiene"
                                size='small'
                                value={internalFilters.createdBy}
                                onChange={handleFilterChange('createdBy')}
                                fullWidth
                            />

                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <ScrollableTableContainer>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>
                            <TableCell>
                                ID de orden de venta
                            </TableCell>
                            <TableCell>
                                ID de tarjeta en cartera
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'dealId')}
                                    direction={order.find(o => o.field === 'dealId')?.order || 'asc'}
                                    onClick={() => handleRequestSort('dealId')}
                                >
                                    Negociación
                                </TableSortLabel>
                            </TableCell>


                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'airlineName')}
                                    direction={order.find(o => o.field === 'airlineName')?.order || 'asc'}
                                    onClick={() => handleRequestSort('airlineName')}
                                >
                                    Compañía aérea
                                </TableSortLabel>
                            </TableCell>


                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'amountAuthorized')}
                                    direction={order.find(o => o.field === 'amountAuthorized')?.order || 'asc'}
                                    onClick={() => handleRequestSort('amountAuthorized')}
                                >
                                    Monto autorizado
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'fileNumber')}
                                    direction={order.find(o => o.field === 'fileNumber')?.order || 'asc'}
                                    onClick={() => handleRequestSort('fileNumber')}
                                >
                                    File
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'operatorName')}
                                    direction={order.find(o => o.field === 'operatorName')?.order || 'asc'}
                                    onClick={() => handleRequestSort('operatorName')}
                                >
                                    Operador
                                </TableSortLabel>
                            </TableCell>


                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'authorizationCode')}
                                    direction={order.find(o => o.field === 'authorizationCode')?.order || 'asc'}
                                    onClick={() => handleRequestSort('authorizationCode')}
                                >
                                    Código de autorización
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdAt')}
                                    direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdAt')}
                                >
                                    Fecha de autorización
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdBy')}
                                    direction={order.find(o => o.field === 'createdBy')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdBy')}
                                >
                                    Autorizado por
                                </TableSortLabel>
                            </TableCell>


                            <TableCell>
                                Próximo a expirar
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'updatedAt')}
                                    direction={order.find(o => o.field === 'updatedAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('updatedAt')}
                                >
                                    Fecha de actualización
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'updatedBy')}
                                    direction={order.find(o => o.field === 'updatedBy')?.order || 'asc'}
                                    onClick={() => handleRequestSort('updatedBy')}
                                >
                                    Actualizada por
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow
                                key={item.id}
                                sx={{

                                    backgroundColor: item.expiringSoon
                                        ? 'rgba(255, 99, 71, 0.2)'  // Fondo rojo claro si está próximo a vencer y no está eliminada
                                        : 'inherit',
                                    backgroundSize: '16px 16px',
                                    '&:hover': {
                                        backgroundColor: item.expiringSoon
                                            ? 'rgba(255, 99, 71, 0.3)'  // Más intenso al pasar el mouse
                                            : 'inherit',
                                    },
                                    position: 'relative',
                                }}
                                title={item.expiringSoon ? 'Próxima a vencer' : ''}
                            >
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.orderId}</TableCell>
                                <TableCell> <a title={'Abrir detalle de tarjeta en cartera'}
                                               href={getVieDetailCardPortfolioURL(item.cardPortfolioId)}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                >
                                    {item.cardPortfolioId}</a></TableCell>
                                <TableCell> <a title={'Abrir en Bitrix'}
                                               href={`https://traveller.bitrix24.es/crm/deal/details/${item.dealId}/`}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                >
                                    {item.dealId}</a></TableCell>
                                <TableCell>{item.airlineName? item.airlineName : travellerPosName}</TableCell>
                                <TableCell>{item.amountAuthorized} USD</TableCell>
                                <TableCell>{item.fileNumber ? item.fileNumber : '-'}</TableCell>
                                <TableCell>{item.operatorName? item.operatorName : travellerPosName}</TableCell>
                                <TableCell>{item.authorizationCode}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <TableCell>{item.expiringSoon ? 'Si' : 'No'}</TableCell>
                                <TableCell>{item.updatedAt ? item.updatedAt : '-'}</TableCell>
                                <TableCell>{item.updatedBy ? item.updatedBy : '-'}</TableCell>
                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={14} />}
                    </TableBody>
                </Table>
                </ScrollableTableContainer>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default AuthorizationsList;

import React, {useEffect} from 'react';
import {Box, Button, Card, CardContent, InputAdornment, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {appRoutes, emailValidation} from "../../../../core/const";
import {IBitrixDealInfoToOrderBamboo} from "../../types";
import {MuiTelInput} from "mui-tel-input";

export interface SaleOrderBambooCreateFormProps {
    handleSubmitForm: (value: ICreateSaleOrderFormValue) => void;
    handleCheckDeal: (dealId: string) => void;
    dealInfo: IBitrixDealInfoToOrderBamboo | null;
    loading: boolean;
}

export interface ICreateSaleOrderFormValue {
    amount: string;
    dealId: string;
    fileNumber: string;
    departureDate: string;
    payerEmail: string;
    payerPhone: string;
    description: string;
}

export const SaleOrderBambooCreateForm: React.FC<SaleOrderBambooCreateFormProps> = ({
                                                                                        handleSubmitForm,
                                                                                        loading,
                                                                                        dealInfo,
                                                                                        handleCheckDeal,
                                                                                    }) => {

    const methods = useForm<ICreateSaleOrderFormValue>({
        mode: 'all',
        defaultValues: {
            amount: '', dealId: '', fileNumber: '',
            departureDate: '', payerEmail: '', payerPhone: '', description: ''
        }
    });


    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues, setValue, trigger, watch
    } = methods;
    const watchFields = watch(["fileNumber", "departureDate",'payerEmail','payerPhone']);

    useEffect(() => {
        const fileNumberValue = dealInfo?.fileNumber || '';
        const departureDateValue = dealInfo?.departureDate || '';
        const payerEmailValue = dealInfo?.email || '';
        const payerPhoneValue = dealInfo?.phone || '';
        setValue('fileNumber', fileNumberValue);
        setValue('departureDate', departureDateValue);
        setValue('payerEmail', payerEmailValue);
        setValue('payerPhone', payerPhoneValue);
        if (watchFields[0] != fileNumberValue) {
            trigger('fileNumber');
        }
        if (watchFields[1] != departureDateValue) {
            trigger('departureDate');
        }
        if (watchFields[2] != payerEmailValue) {
            trigger('payerEmail');
        }
        if (watchFields[3] != payerPhoneValue) {
            trigger('payerPhone');
        }
    }, [dealInfo]);


    return (
        <>
            <Card sx={{width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{'Crear nueva orden de venta de Bamboo'}</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}>
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{
                                        min: {value: 1, message: 'El valor mínimo es 1'},
                                        required: {value: true, message: 'Este campo es requerido'},
                                        pattern: {
                                            value: /^[0-9]*[.,]?[0-9]+$/,
                                            message: 'El campo monto debe ser un número válido'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Monto"
                                            required={true}
                                            size="small"
                                            type="text"
                                            helperText={errors.amount ? errors.amount.message : ''}
                                            error={!!errors.amount}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                            }}
                                            {...field}

                                        />
                                    )}
                                />
                            </Box>


                            <Box display="flex" alignItems="center" gap={1} flex={2} p={1}>

                                <Controller
                                    name="dealId"
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido", // Validación de campo requerido
                                        pattern: {value: /^[0-9]*$/, message: "Solo se permiten números"} // Validación para solo números
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            fullWidth
                                            label="Número de la negociación"
                                            placeholder="Inserte el número y presione enter"
                                            required
                                            size="small"
                                            type="text"
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                                field.onChange(value);
                                                setValue('fileNumber', '');
                                                setValue('departureDate', '');
                                                trigger('fileNumber');
                                                trigger('departureDate');
                                                // Notifica a react-hook-form del cambio
                                            }}
                                            error={!!errors.dealId}
                                            helperText={errors.dealId ? errors.dealId.message : ''}
                                            onKeyDown={(event) => {
                                                if (event.keyCode == 13 && !errors.dealId) {
                                                    handleCheckDeal(getValues().dealId)
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}>
                                <Controller
                                    name="fileNumber"
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido", // Validación de campo requerido
                                        pattern: {value: /^[0-9]*$/, message: "Solo se permiten números"} // Validación para solo números
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Número de file"
                                            required={true}
                                            size="small"
                                            type="text"
                                            inputProps={{
                                                readOnly: true,
                                                style: {cursor: 'not-allowed'}, // Aplica el cursor en el nivel del input
                                            }}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                                                }
                                            }}
                                            helperText={errors.fileNumber ? errors.fileNumber.message : ''}
                                            error={!!errors.fileNumber}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" gap={2} flex={2} p={1}>
                                <Controller
                                    name="departureDate"
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido", // Validación de campo requerido
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Fecha de salida"
                                            required={true}
                                            size="small"
                                            type="text"
                                            inputProps={{
                                                readOnly: true,
                                                style: {cursor: 'not-allowed'}, // Aplica el cursor en el nivel del input
                                            }}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                                                }
                                            }}
                                            helperText={errors.departureDate ? errors.departureDate.message : ''}
                                            error={!!errors.departureDate}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="payerPhone"
                                    control={control}
                                    rules={{
                                        required: 'El número de teléfono del pagador es obligatorio',
                                        validate: (value) => value.length > 0 || 'Debe ingresar un número válido',
                                    }}
                                    render={({field}) => (
                                        <MuiTelInput
                                            {...field}
                                            defaultCountry="UY"
                                            error={!!errors.payerPhone}
                                            helperText={errors.payerPhone?.message}
                                            required
                                            fullWidth
                                            label="Número de teléfono del pagador"
                                            variant="outlined"
                                            size={"small"}
                                        />
                                    )}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="payerEmail"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: ' + 255 + ' caracteres'

                                        },
                                        pattern: {
                                            value: emailValidation.value,
                                            message: 'Ingrese un email valido',
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Correo electrónico del pagador"
                                            type={"text"}
                                            disabled={loading}
                                            margin="normal"
                                            helperText={errors.payerEmail ? errors.payerEmail.message : ''}
                                            error={!!errors.payerEmail}
                                            {...field}
                                            id="email"
                                            name="email"
                                            size={"small"}
                                        />
                                    )}
                                />
                            </Box>
                            </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={1} flex={1} p={1}>
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 150,
                                            message: 'El campo excede el largo máximo permitido de: ' + 150 + ' caracteres'

                                        },
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Observaciones"
                                            multiline
                                            rows={4}
                                            size={'small'}
                                            type="text"
                                            helperText={errors.description ? errors.description.message : ''}
                                            error={!!errors.description}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>


                        </Box>

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(appRoutes.bambooSaleOrder.list)}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};
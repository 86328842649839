import React, {useState} from 'react';
import {Card, CardContent, FormControl, Grid, MenuItem, Select, TextField, Typography,} from '@mui/material';
import Box from "@mui/material/Box";
import {calculateTariff, tariffCalculatorCardStamp} from "../../helper";

const TariffCalculator = () => {
    const [cardStamp, setCardStamp] = useState('Master');
    const [fee, setFee] = useState(1);
    const [baseAmountUSD, setBaseAmountUSD] = useState(0);

    const tariff = calculateTariff(cardStamp,fee);
    const iva = 1.22;
    const recharge = tariff == null? 'No disponible': (iva * tariff).toFixed(1);
    const totalAmountInUSD = tariff == null? 'No disponible': (baseAmountUSD + (baseAmountUSD * Number(recharge) /100)).toFixed(0);

    return (
        <Card sx={{width: '100%'}}>
            <CardContent>
                <Typography variant="h5" style={{fontWeight: 'bold'}}
                            p={1}>{'Cálculo de aranceles'}</Typography>
                <br/>
                <Box component="form" display="flex" flexDirection="column" gap={1}>
            <Grid container spacing={2} pb={1}>
                <Grid item xs={6}>
                    <Typography>Selecciona el Sello:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Select
                            size="small"
                            labelId="sello-label"
                            value={cardStamp}
                            onChange={(e) => setCardStamp(e.target.value)}
                        >
                            {tariffCalculatorCardStamp.map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Typography>Cuotas:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Select
                            size="small"
                            value={fee}
                            onChange={(e) => setFee(+e.target.value)}
                        >
                            {[...Array(13)].map((_, index) => (
                                <MenuItem key={index} value={index}>
                                    {index}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Typography>Arancel:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        value={tariff||'No disponible'}
                        inputProps={{
                            readOnly: true,
                            style: {cursor: 'not-allowed'}, // Aplica el cursor en el nivel del input
                        }}
                        InputProps={{
                            style: {
                                backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                            }
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>IVA:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        value={iva.toFixed(2)}
                        inputProps={{
                            readOnly: true,
                            style: {cursor: 'not-allowed'}, // Aplica el cursor en el nivel del input
                        }}
                        InputProps={{
                            style: {
                                backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                            }
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>Importe USD:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        type="number"
                        value={baseAmountUSD}
                        onChange={(e) => setBaseAmountUSD(Number(e.target.value))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>Recargo:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        value={recharge}
                        inputProps={{
                            readOnly: true,
                            style: {cursor: 'not-allowed'}, // Aplica el cursor en el nivel del input
                        }}
                        InputProps={{
                            style: {
                                backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                            }
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>Total USD:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        value={totalAmountInUSD}
                        InputProps={{
                            readOnly: true,
                            style: {
                                backgroundColor: '#f5f5f5', // Cambia el color de fondo para que parezca deshabilitado
                            }
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
                </Box>
            </CardContent>
        </Card>
    );
};

export default TariffCalculator;
import {DocumentTypeEnum} from "../payments/types";
import {IIssueRequirementFromSaleOrder} from "../sale-order-issue-requirements/types";

export interface ICardPortfolioViews {
    userId:number;
    name:string;
    connectedSince:string;
}

export interface IMercureToken {
    token:string
}

export enum CardPortfolioStatus {
    TOTAL_AUTHORIZED = 'TOTAL_AUTHORIZED',
    PARTIAL_AUTHORIZED = 'PARTIAL_AUTHORIZED',
    PARTIAL_AUTHORIZED_WITH_REJECT = 'PARTIAL_AUTHORIZED_WITH_REJECT',
    PARTIAL_AUTHORIZED_WITH_PRE_AUTHORIZATION = 'PARTIAL_AUTHORIZED_WITH_PRE_AUTHORIZATION',
    PARTIAL_AUTHORIZED_WITH_PRE_AUTHORIZATION_WITH_REJECT = 'PARTIAL_AUTHORIZED_WITH_PRE_AUTHORIZATION_WITH_REJECT',
    PRE_AUTHORIZED = 'PRE_AUTHORIZED',
    PRE_AUTHORIZED_WITH_REJECT = 'PRE_AUTHORIZED_WITH_REJECT',
    REJECTED = 'REJECTED',
    OPERATE = 'OPERATE',
    FINALIZED = 'FINALIZED',
}


export interface IListCardPortfolio {
    id:number
    orderId:string
    amount:number;
    dealId:string;
    fileNumber:string;
    totalPendingAuthorization:number;
    totalPreauthorized:number;
    departureDate:string;
    toUseInSameIssue:boolean;
    fee:number;
    cardHolder:string;
    cardStamp:string;
    lastFourCardDigit:string;
    sellerName:string;
    createdAt:string;
    status:CardPortfolioStatus;
    views:ICardPortfolioViews[];
    issueRequirements:IIssueRequirementFromSaleOrder[];
}

export interface IFilterCardPortfolio {
    id?:number|string;
    orderId?:string;
    amount?:number|string;
    amountFrom?:number|string;
    amountTo?:number|string;
    dealId?:string;
    fileNumber?:string;
    totalPendingAuthorization?:number|string;
    totalPendingAuthorizationFrom?:number|string;
    totalPendingAuthorizationTo?:number|string;
    totalPreauthorized?:number|string;
    totalPreauthorizedFrom?:number|string;
    totalPreauthorizedTo?:number|string;
    preauthorized?:number|string;
    preauthorizedFrom?:number|string;
    preauthorizedTo?:number|string;
    preauthorizedWithAirlineCode?:string;
    departureDate?:string;
    departureDateFrom?:string;
    departureDateTo?:string;
    toUseInSameIssue?:boolean|string;
    hasActiveRejection?:boolean|string;
    fee?:string;
    cardHolder?:string;
    cardStamp?:string;
    lastFourCardDigit?:string;
    sellerName?:string;
    createdAt?:string;
    createdAtFrom?:string;
    createdAtTo?:string;
    status?:string;
    showFinalized?:boolean|string;
    hasIssueRequirements?:boolean|string;
    issueRequirementId?:string;
}

export interface IDetailICardPortfolio {
    id: number;
    orderId: string;
    amount: number;
    fee: string;
    dealId: string;
    fileNumber: string;
    departureDate: string;
    toUseInSameIssue: boolean;
    cardHolder: string;
    cardStamp: string;
    lastFourCardDigit: string;
    sellerName: string;
    createdAt: string;
    authorized: number;
    preauthorized: number;
    totalPendingAuthorization: number;
    allowCreateAuthorizations: boolean;
    allowEditAuthorizations: boolean;
    allowCreateOrUpdateRejections: boolean;
    allowFinalized: boolean;
    status:CardPortfolioStatus;
    views:ICardPortfolioViews[];
    issueRequirements:IIssueRequirementFromSaleOrder[];
}

export interface IClearInformationCardPortfolioDTO {
    number: string;
    holder: string;
    expiration: string;
    cvv: string;
    documentType: DocumentTypeEnum;
    documentNumber: string;
    fee: number;
}

import React, {Dispatch, SetStateAction, useState} from 'react';

import {
    Box,
    Button, Checkbox,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {Add as AddIcon, FilterList as FilterListIcon, Search as SearchIcon} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {IFilterSaleOrderBamboo, IListSaleOrderBamboo} from "../../types";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import ScrollableTableContainer from "../../../../core/components/ScrollableTableContainer/ScrollableTableContainer";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";
import {CookieService} from "../../../auth/services/CookieService";

interface SaleOrderBambooListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterSaleOrderBamboo;
    setFilters: (filter: IFilterSaleOrderBamboo) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListSaleOrderBamboo[];
    totalElements: number;
    onAdd: () => void;
    onCheckPaymentLinkSend : (id:string)=> void;
    onCheckPaymentReceived : (id:string)=> void;
}

export const defaultFilterSAleOrderBamboo = {
    id: '',
    amount: '',
    amountFrom: '',
    amountTo: '',
    dealId: '',
    fileNumber: '',
    departureDateFrom: '',
    departureDateTo: '',
    payerEmail: '',
    payerPhone: '',
    description: '',
    sellerName: '',
    createdAtFrom: '',
    createdAtTo: '',
    paymentLinkSend: '',
    paymentLinkSendAtFrom: '',
    paymentLinkSendAtTo: '',
    paymentLinkSendBy: '',
    paymentReceived: '',
    paymentReceivedAtFrom: '',
    paymentReceivedAtTo: '',
    paymentReceivedMarkBy: '',
} as IFilterSaleOrderBamboo;

const SaleOrderBambooList: React.FC<SaleOrderBambooListProps> = ({
                                                                     title,
                                                                     search,
                                                                     setSearch,
                                                                     page,
                                                                     setPage,
                                                                     pageSize,
                                                                     totalElements,
                                                                     setPageSize,
                                                                     filters,
                                                                     setFilters,
                                                                     order,
                                                                     setOrder,
                                                                     items,
                                                                     onAdd,
                                                                     onCheckPaymentLinkSend,
                                                                     onCheckPaymentReceived
                                                                 }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterSaleOrderBamboo>(defaultFilterSAleOrderBamboo);
    const [departureDateFrom, setDepartureDateFrom] = useState<Dayjs | null>(null);
    const [departureDateTo, setDepartureDateTo] = useState<Dayjs | null>(null);

    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(null);
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(null);

    const [paymentReceivedAtFrom, setPaymentReceivedAtFrom] = useState<Dayjs | null>(null);
    const [paymentReceivedAtTo, setPaymentReceivedAtTo] = useState<Dayjs | null>(null);

    const [paymentLinkSendAtFrom, setPaymentLinkSendAtFrom] = useState<Dayjs | null>(null);
    const [paymentLinkSendAtTo, setPaymentLinkSendAtTo] = useState<Dayjs | null>(null);
    const hasRoleSeller = CookieService.hasRoleSeller();
    const hasRoleDptoAdmin = CookieService.hasRoleDptoAdmin();
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    const handleChangePaymentLinkSend = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, paymentLinkSend: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, paymentLinkSend: undefined}));
        }

    };

    const handleChangePaymentReceived = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, paymentReceived: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, paymentReceived: undefined}));
        }

    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListSaleOrderBamboo) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: hasRoleSeller?'space-between':'flex-end', mb: 2}} style={{paddingLeft: 0}}>
                {hasRoleSeller &&<Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nueva orden de venta de Bamboo
                </Button>}
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1,minWidth: 370}}>
                            <TextField
                                label="ID igual a"
                                size='small'
                                value={internalFilters.id}
                                onChange={handleFilterChange('id')}
                                fullWidth
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountFrom', value);
                                }}
                                label="Monto mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountTo', value);
                                }}
                                label="Monto menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amount}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amount', value);
                                }}
                                label="Monto igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                label="Número de la negociación igual a"
                                size='small'
                                value={internalFilters.dealId}
                                onChange={handleFilterChange('dealId')}
                                fullWidth
                            />
                            <TextField
                                label="Número de file igual a"
                                size='small'
                                value={internalFilters.fileNumber}
                                onChange={handleFilterChange('fileNumber')}
                                fullWidth
                            />
                            <TextField
                                label="Email del pagador contiene"
                                size='small'
                                value={internalFilters.payerEmail}
                                onChange={handleFilterChange('payerEmail')}
                                fullWidth
                            />

                            <TextField
                                label="Número de teléfono del pagador contiene"
                                size='small'
                                value={internalFilters.payerPhone}
                                onChange={handleFilterChange('payerPhone')}
                                fullWidth
                            />

                            <TextField
                                label="Descripción contiene"
                                size='small'
                                value={internalFilters.description}
                                onChange={handleFilterChange('description')}
                                fullWidth
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de salida desde"
                                        format="YYYY-MM-DD"
                                        value={departureDateFrom ? dayjs(departureDateFrom) : null}
                                        onChange={(newValue) => {
                                            setDepartureDateFrom(newValue);
                                            handleFilterChangeValue('departureDateFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de salida hasta"
                                        format="YYYY-MM-DD"
                                        value={departureDateTo ? dayjs(departureDateTo) : null}
                                        onChange={(newValue) => {
                                            setDepartureDateTo(newValue);
                                            handleFilterChangeValue('departureDateTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={departureDateFrom ? dayjs(departureDateFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de creación desde"
                                        format="YYYY-MM-DD"
                                        value={createdAtFrom ? dayjs(createdAtFrom) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtFrom(newValue);
                                            handleFilterChangeValue('createdAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de creación hasta"
                                        format="YYYY-MM-DD"
                                        value={createdAtTo ? dayjs(createdAtTo) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtTo(newValue);
                                            handleFilterChangeValue('createdAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={createdAtFrom ? dayjs(createdAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de envío de link de pago desde"
                                        format="YYYY-MM-DD"
                                        value={paymentLinkSendAtFrom ? dayjs(paymentLinkSendAtFrom) : null}
                                        onChange={(newValue) => {
                                            setPaymentLinkSendAtFrom(newValue);
                                            handleFilterChangeValue('paymentLinkSendAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de envío de link de pago hasta"
                                        format="YYYY-MM-DD"
                                        value={paymentLinkSendAtTo ? dayjs(paymentLinkSendAtTo) : null}
                                        onChange={(newValue) => {
                                            setPaymentLinkSendAtTo(newValue);
                                            handleFilterChangeValue('paymentLinkSendAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={paymentLinkSendAtFrom ? dayjs(paymentLinkSendAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de pago recibido desde"
                                        format="YYYY-MM-DD"
                                        value={paymentReceivedAtFrom ? dayjs(paymentReceivedAtFrom) : null}
                                        onChange={(newValue) => {
                                            setPaymentReceivedAtFrom(newValue);
                                            handleFilterChangeValue('paymentReceivedAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de pago recibido hasta"
                                        format="YYYY-MM-DD"
                                        value={paymentReceivedAtTo ? dayjs(paymentReceivedAtTo) : null}
                                        onChange={(newValue) => {
                                            setPaymentReceivedAtTo(newValue);
                                            handleFilterChangeValue('paymentReceivedAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={paymentReceivedAtFrom ? dayjs(paymentReceivedAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>
                            <FormControl fullWidth>
                                <InputLabel id="payment-link-send-label">Link de pago enviado</InputLabel>
                                <Select
                                    size='small'
                                    labelId="payment-link-send-select-label"
                                    id="o-use-same-issue-select-label"
                                    value={internalFilters.paymentLinkSend}
                                    label="Link de pago enviado"
                                    onChange={handleChangePaymentLinkSend}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="payment-pay-send-label">Pago recibido</InputLabel>
                                <Select
                                    size='small'
                                    labelId="payment-pay-send-select-label"
                                    id="payment-pay-select-label"
                                    value={internalFilters.paymentLinkSend}
                                    label="Pago recibido"
                                    onChange={handleChangePaymentReceived}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Vendedor contiene"
                                size='small'
                                value={internalFilters.sellerName}
                                onChange={handleFilterChange('sellerName')}
                                fullWidth
                            />

                            <TextField
                                label="Link de pago enviado por contiene"
                                size='small'
                                value={internalFilters.paymentLinkSendBy}
                                onChange={handleFilterChange('paymentLinkSendBy')}
                                fullWidth
                            />

                            <TextField
                                label="Pago recibido actualizado por"
                                size='small'
                                value={internalFilters.paymentReceivedMarkBy}
                                onChange={handleFilterChange('paymentReceivedMarkBy')}
                                fullWidth
                            />

                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <ScrollableTableContainer>
                    <Table sx={{width: '100%'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    ID
                                </TableCell>
                                <TableCell sx={{width: '10%'}}>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'amount')}
                                        direction={order.find(o => o.field === 'amount')?.order || 'asc'}
                                        onClick={() => handleRequestSort('amount')}
                                    >
                                        Monto
                                    </TableSortLabel>
                                </TableCell>


                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'dealId')}
                                        direction={order.find(o => o.field === 'dealId')?.order || 'asc'}
                                        onClick={() => handleRequestSort('dealId')}
                                    >
                                        Negociación
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'fileNumber')}
                                        direction={order.find(o => o.field === 'fileNumber')?.order || 'asc'}
                                        onClick={() => handleRequestSort('fileNumber')}
                                    >
                                        File
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell sx={{width: '10%'}}>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'departureDate')}
                                        direction={order.find(o => o.field === 'departureDate')?.order || 'asc'}
                                        onClick={() => handleRequestSort('departureDate')}
                                    >
                                        Salida
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'payerEmail')}
                                        direction={order.find(o => o.field === 'payerEmail')?.order || 'asc'}
                                        onClick={() => handleRequestSort('payerEmail')}
                                    >
                                        Email del pagador
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'payerPhone')}
                                        direction={order.find(o => o.field === 'payerPhone')?.order || 'asc'}
                                        onClick={() => handleRequestSort('payerPhone')}
                                    >
                                        Número de teléfono del pagador
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'description')}
                                        direction={order.find(o => o.field === 'description')?.order || 'asc'}
                                        onClick={() => handleRequestSort('description')}
                                    >
                                        Descripción
                                    </TableSortLabel>
                                </TableCell>


                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'sellerName')}
                                        direction={order.find(o => o.field === 'sellerName')?.order || 'asc'}
                                        onClick={() => handleRequestSort('sellerName')}
                                    >
                                        Vendedor
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'createdAt')}
                                        direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                        onClick={() => handleRequestSort('createdAt')}
                                    >
                                        Fecha de creación
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'paymentLinkSend')}
                                        direction={order.find(o => o.field === 'paymentLinkSend')?.order || 'asc'}
                                        onClick={() => handleRequestSort('paymentLinkSend')}
                                    >
                                        Link de pago enviado
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'paymentLinkSendAt')}
                                        direction={order.find(o => o.field === 'paymentLinkSendAt')?.order || 'asc'}
                                        onClick={() => handleRequestSort('paymentLinkSendAt')}
                                    >
                                        Fecha de envío del link de pago
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'paymentLinkSendBy')}
                                        direction={order.find(o => o.field === 'paymentLinkSendBy')?.order || 'asc'}
                                        onClick={() => handleRequestSort('paymentLinkSendBy')}
                                    >
                                        Link de pago enviado por
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'paymentReceived')}
                                        direction={order.find(o => o.field === 'paymentReceived')?.order || 'asc'}
                                        onClick={() => handleRequestSort('paymentReceived')}
                                    >
                                       Pago recibido
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'paymentReceivedAt')}
                                        direction={order.find(o => o.field === 'paymentReceivedAt')?.order || 'asc'}
                                        onClick={() => handleRequestSort('paymentReceivedAt')}
                                    >
                                        Fecha de actualizado a pago recibido
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'paymentReceivedMarkBy')}
                                        direction={order.find(o => o.field === 'paymentReceivedMarkBy')?.order || 'asc'}
                                        onClick={() => handleRequestSort('paymentReceivedMarkBy')}
                                    >
                                       Actualizado a pago recibido por
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.amount} USD</TableCell>
                                    <TableCell> <a title={'Abrir en Bitrix'}
                                                   href={`https://traveller.bitrix24.es/crm/deal/details/${item.dealId}/`}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                    >
                                        {item.dealId}
                                    </a></TableCell>
                                    <TableCell>{item.fileNumber}</TableCell>
                                    <TableCell>{item.departureDate}</TableCell>
                                    <TableCell>{item.payerEmail}</TableCell>
                                    <TableCell>{item.payerPhone}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell>{item.sellerName}</TableCell>
                                    <TableCell>{item.createdAt}</TableCell>

                                    {hasRoleDptoAdmin &&<TableCell>{item.paymentLinkSend?<Checkbox  disabled checked />:
                                        <Checkbox title={'Marcar como link de pago enviado'}
                                                  checked={false} onClick={()=>onCheckPaymentLinkSend(item.id)}/>}</TableCell>}
                                    {!hasRoleDptoAdmin && hasRoleSeller &&<TableCell>{item.paymentLinkSend?'Si':'No'}</TableCell>}
                                    <TableCell>{item.paymentLinkSendAt ? item.paymentLinkSendAt : '-'}</TableCell>
                                    <TableCell>{item.paymentLinkSendBy ? item.paymentLinkSendBy : '-'}</TableCell>

                                    {hasRoleDptoAdmin &&<TableCell>{item.paymentReceived?<Checkbox  disabled checked />:
                                        <Checkbox title={'Marcar como pago recibido'}
                                                  checked={false} onClick={()=>onCheckPaymentReceived(item.id)}/>}</TableCell>}
                                    {!hasRoleDptoAdmin && hasRoleSeller &&<TableCell>{item.paymentReceived?'Si':'No'}</TableCell>}
                                    <TableCell>{item.paymentReceivedAt ? item.paymentReceivedAt : '-'}</TableCell>
                                    <TableCell>{item.paymentReceivedMarkBy ? item.paymentReceivedMarkBy : '-'}</TableCell>
                                </TableRow>
                            ))}
                            {items.length === 0 && <EmptyTable colSpan={10}/>}
                        </TableBody>
                    </Table>
                </ScrollableTableContainer>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default SaleOrderBambooList;

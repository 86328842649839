import React from "react";
import {Tooltip, IconButton, TableCell, useTheme} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {IIssueRequirementFromSaleOrder} from "../../types";
import IssueRequirementsBody from "../issue-requirement-body/IssueRequirementsBody";

interface Props {
    issueRequirements: IIssueRequirementFromSaleOrder[];
}

const IssueRequirementsCell: React.FC<Props> = ({ issueRequirements }) => {
    return (
        <TableCell>
            <IssueRequirementsBody issueRequirements={issueRequirements}/>
        </TableCell>
    );
};

export default IssueRequirementsCell;

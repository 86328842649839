import React, {useCallback, useEffect, useState} from 'react';
import {IAppFilterList, IAppFilterToSave, ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Dialog, DialogTitle, IconButton, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import {IFilterCardPortfolio, IListCardPortfolio} from "../../types";
import {getCardsPortfolio} from "../../services";
import CardsPortfolioList from "../../components/CardsPortfolioList/CardsPortfolioList";
import {appRoutes} from "../../../../core/const";
import {deleteCustomFilter, listCustomFilters, saveCustomFilter} from "../../../../core/services";
import {CustomFilterForm, ICustomFilterFormValue} from "../../../../core/components/CustomFilterForm/CustomFilterForm";
import DraggablePaperComponent from "../../../../core/components/DraggablePaperComponent/DraggablePaperComponent";
import CloseIcon from "@mui/icons-material/Close";
import useMercure from "../../hooks/useMercure";
import {IDetailAirline} from "../../../airlines/types";
import {getAllAirlines} from "../../../airlines/services";
import {getAllIssueRequirements} from "../../../sale-order-issue-requirements/services";
import {IListIssueRequirement} from "../../../sale-order-issue-requirements/types";

export const defaultCardPortfolioFilter:IFilterCardPortfolio = {
    id: '',
    orderId: '',
    amount: '',
    amountFrom: '',
    amountTo: '',
    dealId: '',
    fileNumber: '',
    totalPendingAuthorization:'',
    totalPendingAuthorizationFrom:'',
    totalPendingAuthorizationTo:'',
    totalPreauthorized:'',
    totalPreauthorizedFrom:'',
    totalPreauthorizedTo:'',
    preauthorized:'',
    preauthorizedFrom:'',
    preauthorizedTo:'',
    preauthorizedWithAirlineCode:'',
    departureDate: '',
    departureDateFrom: '',
    departureDateTo: '',
    toUseInSameIssue: '',
    hasActiveRejection: '',
    fee: '',
    cardHolder: '',
    cardStamp: '',
    lastFourCardDigit: '',
    sellerName: '',
    createdAt: '',
    createdAtFrom: '',
    createdAtTo: '',
    status: '',
    showFinalized: 'false',
    hasIssueRequirements:'',
    issueRequirementId:''
} as IFilterCardPortfolio;

const defaultSorts:ISort[] = [{'field': 'createdAt', order: 'desc'}];
const cardPortfolioViewId = 'cards-portfolio-list';

const CardsPortfolioListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>(defaultSorts);
    const [filters, setFilters] = useState<IFilterCardPortfolio>(defaultCardPortfolioFilter);
    const [customFilters, setCustomFilters] = useState<IAppFilterList<IFilterCardPortfolio>[]>([]);
    const [items, setItems] = useState<IListCardPortfolio[]>([]);
    const [activeCustomFilterAppliedId, setActiveCustomFilterAppliedId] = useState<number|null>(null);
    const [showModalCreateFilter, setShowCreateCustomFilter] = useState<boolean>(false);
    const [airlines, setAirlines] = useState<IDetailAirline[]>([]);
    const [issueRequirements, setIssueRequirements]= useState<IListIssueRequirement[]>([]);
    const navigate = useNavigate();


    const handleUpdate = useCallback((elementId: number, connectedUsers: IListCardPortfolio['views']) => {
        setItems((prevElements) =>
            prevElements.map((el) =>
                el.id === elementId ? { ...el, views: connectedUsers } : el
            )
        );
    }, []);

    // Suscribirse a Mercure
    useMercure(handleUpdate);


    const hasAnyFilterValue = (): boolean => {
        return Object.values(filters).some(value => value !== '');
    };

    const loadCardsPortfolio = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getCardsPortfolio(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de tarjetas en cartera')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);


    const loadCustomFilters = useCallback(async () => {
        try {
            setLoading(true);
            const customFiltersResult:IAppFilterList<IFilterCardPortfolio>[] = (await listCustomFilters<IFilterCardPortfolio>(cardPortfolioViewId)).data;
            setCustomFilters(customFiltersResult);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de filtros guardados para esta interfaz')
            });
            setLoading(false);
        }
    }, []);

    const loadAirlines = async () => {
        try {
            setLoading(true);
            const result = (await getAllAirlines()).data;
            setAirlines(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar las compañías aéreas')
            });
        } finally {
            setLoading(false);
        }
    }

    const loadIssueRequirements = async ()=>{
        try {
            setLoading(true);
            const result = (await getAllIssueRequirements()).data;
            setIssueRequirements([...result]);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los requerimientos para emisión')
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadCardsPortfolio();
    }, [loadCardsPortfolio]);

    useEffect(() => {
        loadCustomFilters();
    }, [loadCustomFilters]);

    useEffect(() => {
        loadAirlines();
    }, []);

    useEffect(() => {
        loadIssueRequirements();
    }, []);



    const onApplyCustomFilter =  (filter: IAppFilterList<IFilterCardPortfolio> | null)=>{
        if(filter) {
            setActiveCustomFilterAppliedId(filter.id);
             setFilters({...filter.filterValue.filter});
             setOrder(filter.filterValue.sorts);
             setSearch(filter.filterValue.search);
             setPage(0);
        } else {
            setActiveCustomFilterAppliedId(null);
             setFilters({...defaultCardPortfolioFilter});
             setOrder(defaultSorts);
             setSearch('');
             setPage(0);
        }
    }

    const handleSubmitCreateFilter = async (filterFormBody:ICustomFilterFormValue)=>{
        try {
            setLoading(true);
            const filterToSave = {
                ...filterFormBody,
                viewId:cardPortfolioViewId,
                filterValue: JSON.stringify({
                    search:search,
                    sorts:order,
                    filter:filters
                })
            } as IAppFilterToSave;
          const {id} =  (await (saveCustomFilter(filterToSave))).data;
            loadCustomFilters();
            setActiveCustomFilterAppliedId(id);

        }catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al guardar el filtro')
            });
        } finally {
            setLoading(false);
            setShowCreateCustomFilter(false);
        }

    }

    const onDeleteCustomFilter = async (id: number)=>{
        try {
            setLoading(true);
            await deleteCustomFilter(id);
            try{
                await loadCustomFilters();
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error allistar los filtros')
                });
            }

        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar el filtro')
            });
        }
        finally {
            setLoading(false);
        }
    }

    const onDetail = (id: number) => navigate(appRoutes.cardPortfolio.detail.replace(':id', id + ''))

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <CardsPortfolioList title={'Tarjetas en cartera'}
                                items={items}
                                filters={filters}
                                setFilters={setFilters}
                                order={order}
                                setOrder={setOrder}
                                search={search}
                                setSearch={setSearch}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                totalElements={totalElements}
                                onDetail={onDetail}
                                customFilters={customFilters}
                                activeCustomFilterId={activeCustomFilterAppliedId}
                                onApplyCustomFilter={onApplyCustomFilter}
                                onDeleteCustomFilter={onDeleteCustomFilter}

                                showButtonToSaveCustomFilter={!activeCustomFilterAppliedId && (search!==''||hasAnyFilterValue())}
                                onClickSaveCustomFilter={()=>setShowCreateCustomFilter(true)}
                                airlines={airlines}
                                issueRequirements={issueRequirements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
            {showModalCreateFilter && <Dialog
                open={showModalCreateFilter}
                PaperComponent={DraggablePaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle
                    style={{ cursor: 'move', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    id="draggable-dialog-title"
                >
                    Crear nuevo filtro personalizado
                    <IconButton
                        aria-label="close"
                        onClick={()=>setShowCreateCustomFilter(false)}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <CustomFilterForm handleClose={()=>setShowCreateCustomFilter(false)} handleSubmitForm={handleSubmitCreateFilter} />
            </Dialog>}
        </>
    );
}

export default CardsPortfolioListContainer;

import {IFilterListAllCardPortfolioAuthorization} from "./types";

export const defaultListAllCardPortfolioAuthorizationsFilter:IFilterListAllCardPortfolioAuthorization = {
    id: '',
    orderId: '',
    cardPortfolioId: '',
    airlineCode: '',
    amountAuthorized: '',
    amountAuthorizedFrom: '',
    amountAuthorizedTo: '',
    operatorId: '',
    dealId: '',
    fileNumber: '',
    authorizationCode: '',
    createdAtFrom: '',
    createdAtTo: '', // Este campo es obligatorio y se ha inicializado
    expiringSoon: '', // Acepta booleano o cadena, se asigna cadena vacía
    createdBy: '',
    search: ''
}